<template>
    <div class="cloud-wrap">
        <div class="cloud-box" ref="cloudEl" />
    </div>
</template>

<script>
import * as echarts from "echarts";
// import echarts from "echarts";
import wordcloud from "echarts-wordcloud";
export default {
    props: ["lists"],
    data() {
        return {
            wordcloud: wordcloud,
        };
    },
    mounted() {
        
        // console.log(this.lists)
    },
    methods: {
        drawCloud(lists) {
            let myChart = echarts.init(this.$refs.cloudEl);
            var option = {
                tooltip: {
                    show: true,
                },
                formatter: function (params) {
                    // console.log(params.data); // 当我们想要自定义提示框内容时，可以先将鼠标悬浮的数据打印出来，然后根据需求提取出来即可
                    return params.name;
                },
                series: [
                    {
                        type: "wordCloud",
                        //用来调整词之间的距离
                        gridSize: 20,
                        //用来调整字的大小范围
                        // Text size range which the value in data will be mapped to.
                        // Default to have minimum 12px and maximum 60px size.
                        sizeRange: [14, 50],
                        // Text rotation range and step in degree. Text will be rotated randomly in range [-90,                                                                             90] by rotationStep 45
                        rotationRange: [0, 0],
                        //随机生成字体颜色
                        // maskImage: maskImage,
                        textStyle: {
                            fontFamily: "sans-serif",
                            color: function () {
                                return (
                                    "rgb(" +
                                    Math.round(Math.random() * 255) +
                                    ", " +
                                    Math.round(Math.random() * 255) +
                                    ", " +
                                    Math.round(Math.random() * 255) +
                                    ")"
                                );
                            },
                        },
                        left: "center",
                        top: "center",
                        right: null,
                        bottom: null,
                        width: "200%",
                        height: "200%",
                        data:lists,
                    },
                ],
            };
            myChart.setOption(option);
        },
    },
};
</script>
<style lang="scss" scoped>
.cloud-wrap {
    width: 520px;
    height: 220px;
    text-align: left;
    .cloud-box {
        width: 100%;
        height: 100%;
    }
}
</style>