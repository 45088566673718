<template>
    <div class="hotwords">
        <div class="index-top">
            <div class="index-title">
                <div class="title">素材标签</div>
            </div>
        </div>
        <div class="category">
            <el-radio v-model="Classradio" label="1">投放对象</el-radio>
            <el-radio v-model="Classradio" label="2">行业热词</el-radio>
        </div>
        <div class="cate-input gongdan">
            <div class="dan" v-if="Classradio == 1">
                <div class="text">
                    <el-tooltip class="item" effect="dark" popper-class="testtooltip" content="请尽量从多维度描述你投放产品的特征，如儿童
英语，可填写早教、补习班、少儿英语。" placement="top">
                        <i class="iconfont icon-wenhao"></i>
                    </el-tooltip>投放对象
                </div>
                <div class="inputbox">
                    <div @click="Objshow=false;Busshow=false">
                        <el-select v-model="keyword" filterable remote reserve-keyword placeholder="请输入关键词" :remote-method="remoteMethod" :loading="loading" style="margin-left:12px" @change="getChange">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">

                            </el-option>
                        </el-select>
                    </div>
                    <div class="pop" v-if="Objshow" @click.stop="" v-loading="Vloading">
                        <WordCloudChart ref="WordCloudChart"></WordCloudChart>
                        <el-checkbox-group v-model="checkedCities">
                            <el-checkbox v-for="city in cities" :label="city" :key="city">{{city}}</el-checkbox>
                        </el-checkbox-group>
                        <div style="padding-left:30px; font-size:20px;margin-bottom:10px">
                            已选: {{checkedCities.length}}/10
                        </div>
                        <el-row style="display:flex; justify-content:  center;">
                            <!-- <el-button type="primary" @click="oncheckedCities()">一键全选</el-button> -->
                            <el-button type="primary" @click="ensure">确定</el-button>
                            <el-button @click="butquxiao">关闭</el-button>
                        </el-row>
                    </div>
                </div>
            </div>
            <div class="dan" v-if="Classradio == 2">
                <div class="text">
                    <el-tooltip class="item" effect="dark" popper-class="testtooltip" content="请先选择您感兴趣的行业" placement="top">
                        <i class="iconfont icon-wenhao"></i>
                    </el-tooltip>行业
                </div>
                <div class="inputbox">
                    <div class="block">
                        <el-cascader :options="industry" ref="cascaderHandle" :props="optionProps" clearable style="margin-left:38px" v-model="business" @change="handleChange"></el-cascader>
                    </div>
                    <div class="pop" v-if="Busshow" @click.stop="" v-loading="Busloading">
                        <WordCloudChart ref="WordCloudChart"></WordCloudChart>
                        <el-checkbox-group v-model="recommend" :max="1">
                            <el-checkbox v-for="city in hangye" :label="city.name" :key="city.value">{{city.name}}</el-checkbox>
                        </el-checkbox-group>
                        <div style="padding-left:30px; font-size:20px;margin-bottom:10px">
                            当前选中关键词为: {{recommend[0]}}
                        </div>
                        <el-row style="display:flex; justify-content:  center;">
                            <el-button @click="butquxiao">关闭</el-button>
                            <el-button type="primary" @click="ensureBus">确定</el-button>
                            <el-button type="primary" style="margin-left:20px" @click="copyTemplate(hangye)">一键复制</el-button>
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
        <div class="cate-search">
            <div class="searchList">
                <div class="dan">
                    <div class="text">投放策略 </div>
                    <div class="inputbox">
                        <el-select v-model="tactics" placeholder="请选择投放策略">
                            <el-option v-for="item in tacticsList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="dan">
                    <div class="text">投放媒体</div>
                    <div class="inputbox">
                        <el-select v-model="media" placeholder="请选择投放媒体">
                            <el-option v-for="item in mediaList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="dan">
                    <div class="text">投放目标</div>
                    <div class="inputbox">
                        <el-select v-model="target" placeholder="请选择投放目标">
                            <el-option v-for="item in targetList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="searchButton">
                <div v-if="Classradio==1">
                    <el-button type="text" @click="emptyObj">清空</el-button>
                    <el-button type="primary" plain @click="ensure" class="chaxun">查询</el-button>
                </div>
                <div v-if="Classradio==2">
                    <el-button type="text" @click="emptyBus">清空</el-button>
                    <el-button type="primary" plain @click="ensureBus" class="chaxun">查询</el-button>
                </div>
            </div>
        </div>
        <div class="catesubmit" v-loading="Catesubmit">
            <div class="catetitle">投放建议<span>据近7天投放数据，以下为近期定向效果较优的经验，以供参考。</span></div>
            <div class="catesList">
                <div class="dan">
                    <div class="title">
                        <div class="text">创意标签<el-tooltip class="item" effect="dark" popper-class="testtooltip" content="请在新建计划或者修改计划时，添加如下标签能更好命中目标用户。" placement="top">
                                <i class="iconfont icon-wenhao"></i>
                            </el-tooltip>
                        <el-button  v-if="creativeTag.length" type="primary" style="margin-left:20px" @click="copyTemplate(creativeTag)">一键复制</el-button>
                        </div>
                        <!-- <div class="copy"><i class="iconfont icon-fuzhi"></i>复制</div> -->
                    </div>
                    <div class="cateData">
                        <!-- <span v-for="(item,key) in creativeTag" :key="key">{{item}}</span> -->
                        <div v-if="creativeTag.length"><span v-for="(item,key) in creativeTag" :key="key">{{item}}</span></div>
                        <p v-else>暂无内容</p>
                    </div>
                </div>
                <div class="dan">
                    <div class="title">
                        <div class="text">兴趣关键词<el-tooltip class="item" effect="dark" popper-class="testtooltip" content="请在新建计划或者修改计划时，添加如下标签能更好命中目标用户。" placement="top">
                                <i class="iconfont icon-wenhao"></i>
                            </el-tooltip>
                        <el-button v-if="interestClass.length" type="primary" style="margin-left:20px" @click="copyTemplate(interestClass)">一键复制</el-button>
                        </div>
                        <!-- <div class="copy"><i class="iconfont icon-fuzhi"></i>复制</div> -->
                    </div>
                    <div class="cateData">
                        <!-- <span v-for="(item,key) in interestClass" :key="key">{{item}}</span> -->
                        <div v-if="interestClass.length"><span v-for="(item,ke) in interestClass" :key="ke">{{item}}</span></div>
                        <p v-else>暂无内容</p>
                    </div>
                </div>
                <div class="dan">
                    <div class="title">
                        <div class="text">兴趣分类<el-tooltip class="item" effect="dark" popper-class="testtooltip" content="请在新建计划或者修改计划时，添加如下标签能更好命中目标用户。" placement="top">
                                <i class="iconfont icon-wenhao"></i>
                            </el-tooltip>
                            <el-button  v-if="interestKeywords.length" type="primary" style="margin-left:20px" @click="copyTemplate(interestKeywords)">一键复制</el-button>
                        </div>
                        <!-- <div class="copy"><i class="iconfont icon-fuzhi"></i>复制</div> -->
                    </div>
                    <div class="cateData">
                        <div v-if="interestKeywords.length"><span v-for="(item,k) in interestKeywords" :key="k">{{item}}</span></div>
                        <p v-else>暂无内容</p>
                    </div>
                </div>
            </div>

        </div>
        <el-dialog title="提示" :visible.sync="Lxdialog" width="300px">
            <div style="margin:0 auto; width:200px;">
                <img src="https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/xinweijiezt.jpg" style="winth:200px;height:200px" alt="">
                <p style="margin-top:20px;width:200px; text-align:center">联系二郎查小姐姐授权使用</p>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import WordCloudChart from "./WordCloudChart.vue";
import { Loading } from "element-ui";
import { List } from "echarts";
export default {
    data() {
        return {
            Classradio: "1",
            putinInput: "", //投放对象
            cateData: [],
            tactics: "2", //投放策略
            media: "4", //投放媒体
            target: "转化量", //投放目标
            keyword: "", //投放对象
            industry: "", //行业列表
            optionProps: {
                value: "id",
                label: "name",
                children: "list",
                checkStrictly: true,
            },
            tacticsList: [
                ///投放策略列表
                {
                    value: "2",
                    label: "优先跑量",
                },
                {
                    value: "1",
                    label: "均衡优先",
                },
            ],
            mediaList: [
                //投放媒体列表
                {
                    value: "8",
                    label: "T条",
                },
                {
                    value: "4",
                    label: "D音",
                },
                {
                    value: "1",
                    label: "X瓜",
                },
                {
                    value: "3",
                    label: "H山",
                },
                {
                    value: "9",
                    label: "C山甲",
                },
            ],
            targetList: [
                //投放目标列表
                {
                    value: "展现量",
                    label: "展现量",
                },
                {
                    value: "点击量",
                    label: "点击量",
                },
                {
                    value: "转化量",
                    label: "转化量",
                },
            ],

            options: [],
            list: [],
            loading: false,
            Vloading: false,
            Catesubmit: false,
            Busloading: false,
            business: [],
            Objshow: false, //对象词云显示隐藏
            Busshow: false, //行业词云显示隐藏
            checkedCities: [],
            cities: [],
            ccc: [],
            hangye: [], //
            recommend: [], //  选择的内容
            data: [],
            creativeTag: [], //创意
            interestClass: [], //兴趣关键词
            interestKeywords: [], // 兴趣分类


            copyData:"",//复制内容
            Lxdialog:false,//未授权弹窗
        };
    },
    components: {
        WordCloudChart,
        Loading,
    },

    mounted() {
        this.$service.get(this.$api.industry, "", (res) => {
            if(res.code == "5003"){
                this.Lxdialog = true
            }   
            if (res.code == "200") {
                this.industry = res.data;
            }
        });
    },

    methods: {
        // 全选
        oncheckedCities(){
            this.checkedCities = this.cities
        },
        copyTemplate(data) {
            if(data[0].value){
                var dataList = []
                data.forEach((v,k) => {
                    dataList.push(v.name)
                });
                this.copyToClipboard((dataList.toString()).replace(/,/g,"\n\t")); // 需要复制的文本内容
            }else{
                this.copyToClipboard((data.toString()).replace(/,/g,"\n\t")); // 需要复制的文本内容
            }
            this.$message.success('复制成功');
        },

        // 点击复制到剪贴板函数
        copyToClipboard(content) {
            if (window.clipboardData) {
                window.clipboardData.setData('text', content);
            } else {
                (function (content) {
                    document.oncopy = function (e) {
                        e.clipboardData.setData('text', content);
                        e.preventDefault();
                        document.oncopy = null;
                }
            })(content);
            document.execCommand('Copy');
            }
        },
        //一键复制
        onjisqCopy(data){
            this.copyData = 'asdasd'
            console.log(data)
        },

        // 投放选择
        handleChange(value) {
            this.hangye = [];
            this.recommend = [];
            this.Busloading = true;
            this.$refs.cascaderHandle.dropDownVisible = false; //监听值发生变化就关闭它
            this.Busshow = true;
            var screen = {
                firstIndustryId: value[0],
                secondIndustryId: value[1],
                thirdIndustryId: value[2],
            };
            this.$service.get(this.$api.hotIndustry, screen, (res) => {
                if (res.code == "200") {
                    var data = [];
                    data = res.data.map((item) => {
                        return {
                            name: `${item.adKeyword}`,
                            value: `${item.showCnt}`,
                        };
                    });
                    this.hangye = data;
                    this.$refs.WordCloudChart.drawCloud(data);
                }
                this.Busloading = false;
            });
        },
        //投放对象
        remoteMethod(query) {
            this.Objshow = false;
            if (query !== "") {
                var screen = {
                    word: query,
                };
                this.$service.get(this.$api.keywordRelated, screen, (res) => {
                    if(res.code == "5003"){
                        this.Lxdialog = true
                    }
                    if (res.code == "200") {
                        this.ccc = res.data;
                        this.list = res.data.map((item) => {
                            return {
                                value: `${item}`,
                                label: `${item}`,
                            };
                        });
                        this.loading = true;
                        setTimeout(() => {
                            this.loading = false;
                            this.options = this.list.filter((item) => {
                                return (
                                    item.label
                                        .toLowerCase()
                                        .indexOf(query.toLowerCase()) > -1
                                );
                            });
                        }, 200);
                    }
                });
            } else {
                this.options = [];
            }
        },
        //投放对象点选
        getChange() {
            this.checkedCities = [];
            this.data = [];
            this.Vloading = true;
            this.cities = this.ccc;
            this.Objshow = true;
            var screen = {
                keyword: this.keyword,
                target: this.target,
                sortField: this.tactics,
                appCode: this.media,
            };
            this.$service.get(this.$api.keywordCloud, screen, (res) => {
                if(res.code == "5003"){
                    this.Lxdialog = true
                }
                if (res.code == "200") {
                    var data = this.data;
                    data = res.data.data.map((item) => {
                        return {
                            name: `${item.keyword}`,
                            value: `${item.show}`,
                        };
                    });
                    this.$refs.WordCloudChart.drawCloud(data);
                }
                this.Vloading = false;
            });
        },
        //清空对象
        emptyObj() {
            this.target = "";
            this.media = "";
            this.tactics = "";
            this.keyword = "";
        },
        //清空行业
        emptyBus() {
            this.target = "";
            this.media = "";
            this.tactics = "";
            this.business = [];
        },
        butquxiao() {
            this.Objshow = false;
            this.Busshow = false;
            this.business = [];
            this.keyword = "";
        },
        //弹窗确定 查询对象
        ensure() {
            
            if (this.keyword == "") {
                this.$message({
                    message: "请输入投放对象",
                    type: "warning",
                });
            } else if (this.checkedCities == "") {
                this.$message({
                    message: "请输入关键词",
                    type: "warning",
                });
            } else {
                console.log(this.checkedCities)
                this.Objshow = false;
                this.Catesubmit = true;
                var screen = {
                    keywords: this.checkedCities,
                    target: this.target,
                    sortField: this.tactics,
                    appCode: this.media,
                };
                this.$service.get(this.$api.keywordRecomm, screen, (res) => {
                    this.Catesubmit = false;
                    if (res.code == "200") {
                        this.creativeTag = res.data.creativeTag;
                        this.interestClass = res.data.interestClass;
                        this.interestKeywords = res.data.interestKeywords;
                    }
                });
            }
        },
        ensureBus(event) {
            
            if (this.business == "") {
                this.$message({
                    message: "请选择行业",
                    type: "warning",
                });
                return;
            }
            if (this.recommend == "") {
                this.$message({
                    message: "请选择关键词",
                    type: "warning",
                });
                return;
            }
            this.Busshow = false;
            this.Catesubmit = true;
            var screen = {
                appCode: this.media,
                sortField: this.tactics,
                target: this.target,
                keywords: this.recommend,
            };
            this.$service.get(this.$api.keywordRecomm, screen, (res) => {
                if (res.code == "200") {
                    this.creativeTag = res.data.creativeTag;
                    this.interestClass = res.data.interestClass;
                    this.interestKeywords = res.data.interestKeywords;
                }
                this.Catesubmit = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.hotwords {
    .category {
        padding: 20px 0;
        border-bottom: 1px solid #ededed;
    }
    .cate-input {
        position: relative;
        border-bottom: 1px solid #ededed;
        padding-top: 20px;
        .dan {
            .text {
                min-width: auto;
            }
        }
    }
    .cate-search {
        padding-bottom: 80px;
        border-bottom: 1px solid #ededed;
        position: relative;
        .searchList {
            display: flex;
            flex-wrap: wrap;
            .dan {
                padding-top: 20px;
                margin-right: 20px;
                display: flex;
                line-height: 33px;
                width: calc(25% - 20px);
                .text {
                    margin-left: 20px;
                    margin-right: 25px;
                    padding-bottom: 10px;
                }
            }
        }
        .searchButton {
            position: absolute;
            left: 20px;
            bottom: 20px;
            z-index: 1;
        }
    }
    .catesubmit {
        padding-top: 30px;
        padding-bottom: 20px;
        .catetitle {
            font-size: 22px;
            font-weight: 500;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ededed;
            span {
                font-size: 14px;
                font-weight: 400;
                margin-left: 20px;
                color: #666;
            }
        }
        .catesList {
            padding-top: 30px;
            display: flex;
            justify-content: space-between;
            .dan {
                width: 32%;
                height: 300px;
                padding: 20px;
                box-shadow: 2px 3px 10px 0px #ededed;
                .title {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 20px;
                    .text {
                        font-size: 16px;
                        font-weight: 500;
                        i {
                            margin-left: 5px;
                        }
                    }
                    .copy {
                        font-size: 16px;
                        color: #396bff;
                        cursor: pointer;
                        i {
                            margin-right: 5px;
                        }
                    }
                }
                .cateData {
                    overflow-y: auto;
                    width: 100%;
                    height: 200px;
                    display: flex;
                    flex-wrap: wrap;
                    span {
                        display: inline-block;
                        cursor: pointer;
                        padding: 3px 5px;
                        margin-bottom: 10px;
                        // background-color: #396bff;
                        background: rgba(57, 107, 255, 0.2);
                        border: 1px solid #396bff;
                        color: #396bff;
                        margin-right: 10px;
                        border-radius: 5px;
                        font-size: 12px;
                        // &:hover {
                        //     background: #396bff;
                        //     border: 1px solid #396bff;
                        //     color: #fff;
                        // }
                    }
                }
            }
        }
    }
    .inputbox {
        width: 50%;
    }

    .my-autocomplete {
        li {
            line-height: normal;
            padding: 7px;

            .name {
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .addr {
                font-size: 12px;
                color: #b4b4b4;
            }

            .highlighted .addr {
                color: #ddd;
            }
        }
    }
    .pop {
        position: absolute;
        top: 60px;
        left: 100px;
        width: 600px;
        // height: 400px;
        padding-bottom: 20px;
        background: #fff;
        border-radius: 5px;
        box-shadow: black 0px 0px 1px;
        z-index: 2;
        .cloud-wrap {
            margin: 0 auto;
            margin-top: 20px;
        }
        .el-checkbox {
            margin: 10px 10px;
        }
    }
    .chaxun {
        position: relative;
        z-index: 999;
    }
}
</style>
<style>
.testtooltip {
    text-align: center;
    width: 260px;
}
</style>
